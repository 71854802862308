import React, { useEffect, useRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';

type FriendlyCaptchaProps = {
  handleCaptchaError: (err: any) => any;
  handleCaptchaSolution: (solution: string) => any;
  sitekey: string;
};

const FriendlyCaptcha: React.FC<FriendlyCaptchaProps> = ({
  handleCaptchaError,
  handleCaptchaSolution,
  sitekey,
}) => {
  const container = useRef();
  const widget = useRef();

  const readyCallback = () => {
    console.log('readyCallback :: Captcha ready to use.');
  };

  const startedCallback = () => {
    console.log('startedCallback :: Captcha starting...');
  };

  const doneCallback = (solution: string) => {
    console.log('Captcha was solved. The form can be submitted.');
    console.log('solution = ', solution);
    handleCaptchaSolution(solution);
  };

  const errorCallback = (err: any) => {
    console.log('There was an error when trying to solve the Captcha.');
    console.log(err);
    handleCaptchaError(err);
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'focus',
        readyCallback,
        startedCallback,
        doneCallback,
        errorCallback,
        skipStyleInjection: false,
        // puzzleEndpoint: "https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
      });
    }

    return () => {
      if (widget.current !== undefined) widget.current.reset();
    };
  }, [container]);

  return <div ref={container} className='frc-captcha' data-sitekey={sitekey} />;
};

export default FriendlyCaptcha;
