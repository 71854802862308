import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Title } from '@keytrade/components-title';
import { Paragraph } from '@keytrade/components-paragraph';
import { FormSpacing } from '@keytrade/components-formspacing';
import { InputText } from '@keytrade/components-inputtext';
import { SelectDropdown } from '@keytrade/components-selectdropdown';
import { TextArea } from '@keytrade/components-textarea';
import { Link } from '@keytrade/components-link';
import { Button } from '@keytrade/components-button';
import { InfoBox } from '@keytrade/components-infobox';
import { validation } from '@keytrade/functions';

import Page, { PageType } from '@/components/Page';
import BlockWrapper from '@/components/BlockWrapper';
import Container from '@/components/Container';
import { LinkStyle } from '@/components/Link';
import InternalLink from '@/components/InternalLink';
import FriendlyCaptcha from '@/components/FriendlyCaptcha';
import useTranslations from '@/hooks/useTranslations';
import RenderOnClientHOC from '@/components/shared/RenderOnClientHOC';

const InfoBoxText = styled.span`
  font-size: 1.4rem;
`;

type Topic =
  | 'admnistration'
  | 'become_client'
  | 'daily_banking'
  | 'investing'
  | 'keyhome'
  | 'logon'
  | 'other';

type AdministrationSubCategory =
  | 'address_change'
  | 'close_account'
  | 'mandate'
  | 'succession';
type DailyBankingSubCategory =
  | 'account_statements'
  | 'cards'
  | 'payments'
  | 'pension_savings'
  | 'saving_accounts';
type InvestingSubCategory =
  | 'coporate_actions'
  | 'keyplan'
  | 'keyprivate'
  | 'securities'
  | 'trading';
type KeyhomeSubCategory =
  | 'credit_request'
  | 'offer_received'
  | 'ongoing_credit'
  | 'simulation';
type LogonSubCategory = 'authentication' | 'help';
type OtherSubCategory = 'about_ktb';

type Topics = Record<
  Topic,
  {
    label: string;
    subcategories?: Record<
      | AdministrationSubCategory
      | DailyBankingSubCategory
      | InvestingSubCategory
      | KeyhomeSubCategory
      | LogonSubCategory
      | OtherSubCategory,
      string
    >;
  }
>;

const usePrefilledTopic = (topics: Topics) => {
  const params = new URLSearchParams(location.search);
  const topicParam = params.get('topic');
  const categoryParam = params.get('category');

  return {
    topic: topics[topicParam]?.label,
    category: topics[topicParam]?.subcategories[categoryParam],
  };
};

const ContactFormPageTemplate: React.FC<PageType> = (props) => {
  const { t } = useTranslations();
  const data = props.data;
  data.contentfulPage = {};
  data.contentfulPage.node_locale = props.pageContext.locale;
  data.allLanguages = {};
  data.allLanguages.nodes = props.pageContext.nav;

  const topics = props.pageContext.topics;
  const { topic: prefilledTopic, category: prefilledCategory } =
    usePrefilledTopic(topics);

  const [name, setName] = useState(null);
  const [nameMissing, setNameMissing] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailMissing, setEmailMissing] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [topic, setTopic] = useState(prefilledTopic || null);
  const [topicMissing, setTopicMissing] = useState(false);
  const [category, setCategory] = useState(prefilledCategory || null);
  const [categoryMissing, setCategoryMissing] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageMissing, setMessageMissing] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [, setCaptchaError] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);

  const handleEmailValidation = (value) => {
    if (validation.email.isValid(value)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
  };

  const handleCaptchaError = () => {
    setCaptchaError(true);
  };

  const handleCaptchaSolution = (solution) => {
    setCaptchaToken(solution);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    if (!name) {
      setNameMissing(true);
    }

    if (!email) {
      setEmailMissing(true);
    }

    if (!topic) {
      setTopicMissing(true);
    }

    const categoryRequired =
      topic &&
      Object.values(topics).find((item) => item.label === topic).subcategories;
    if (categoryRequired && !category) {
      setCategoryMissing(true);
    }

    if (!message) {
      setMessageMissing(true);
    }

    if (
      name &&
      email &&
      !emailInvalid &&
      topic &&
      (category || !categoryRequired) &&
      message
    ) {
      const callBody = {
        senderName: name,
        senderEmail: email,
        subject: category ? `${topic} - ${category}` : topic,
        body: message,
        messageReference: uuidv4(),
      };

      if (captchaToken) {
        callBody.captchaToken = captchaToken;
      }

      fetch(
        `${process.env.GATSBY_API_ORIGIN}/node/backend/cldprxy/webform-service/message`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(callBody),
        },
      )
        .then((res) => {
          if (res.ok) {
            setFormSubmitSuccess(true);
            window.scrollTo(0, 0);
          } else {
            setFormSubmitError(true);
            throw new Error(`HTTP error! status: ${res.status}`);
          }
        })
        .catch((error) => {
          setFormSubmitError(true);
          throw new Error(error);
        });
    }
  };

  return (
    <Page data={data} {...props}>
      <BlockWrapper color='White'>
        <Container narrow>
          <InternalLink
            to={props.pageContext.contactPagePath}
            linkStyle={LinkStyle.BACK}
            margin='7rem 0 0'
          >
            {t('contact.back_contact_page')}
          </InternalLink>
          <Title size='xxxl' level='h1' margin='2.4rem 0 4.8rem'>
            {t('contact.contact_keytrade')}
          </Title>
          {formSubmitSuccess ? (
            <>
              <InfoBox type='success'>{t('contact.submit_success')}</InfoBox>
              <Paragraph margin='3.2rem 0 0'>
                {t('contact.submit_success_2')}
              </Paragraph>
            </>
          ) : (
            <form method='POST' onSubmit={handleFormSubmission} noValidate>
              <FormSpacing>
                <InputText
                  label={t('contact.name')}
                  value={name}
                  onChange={(e) => {
                    setFormSubmitError(false);
                    setNameMissing(false);
                    setName(e.target.value);
                  }}
                  errorMessage={nameMissing && t('missing_field')}
                />
                <InputText
                  type='email'
                  label={t('contact.email')}
                  value={email}
                  onChange={(e) => {
                    setFormSubmitError(false);
                    setEmailMissing(false);
                    setEmailInvalid(false);
                    setEmail(e.target.value);
                  }}
                  onBlur={(e) => handleEmailValidation(e.target.value)}
                  errorMessage={
                    emailMissing
                      ? t('missing_field')
                      : emailInvalid && t('invalid_email')
                  }
                />
                <SelectDropdown
                  id='select-dropdown-contact-topic'
                  label={t('contact.topic')}
                  options={Object.values(topics).map((topic) => ({
                    value: topic.label,
                    label: topic.label,
                  }))}
                  value={topic}
                  onChange={(value) => {
                    setFormSubmitError(false);
                    setTopicMissing(false);
                    setTopic(value);
                  }}
                  placeholder={t('contact.placeholder_topic')}
                  errorMessage={topicMissing && t('missing_field')}
                />
                {topic === topics.keyhome?.label && (
                  <InfoBox type='disclaimer'>
                    <InfoBoxText>
                      {t('contact.simulatorDisclaimer', {
                        simulationTool: (
                          <Link
                            href={t('contact.simulatorToolPath')}
                            targetBlank
                            underline
                          >
                            {t('contact.simulatorToolText')}
                          </Link>
                        ),
                      })}
                    </InfoBoxText>
                  </InfoBox>
                )}
                {topic &&
                  Object.values(topics).find((item) => item.label === topic)
                    .subcategories && (
                    <SelectDropdown
                      id='select-dropdown-contact-category'
                      label={t('contact.category')}
                      options={Object.values(
                        Object.values(topics).find(
                          (item) => item.label === topic,
                        ).subcategories,
                      ).map((category) => ({
                        value: category,
                        label: category,
                      }))}
                      value={category}
                      onChange={(value) => {
                        setFormSubmitError(false);
                        setCategoryMissing(false);
                        setCategory(value);
                      }}
                      placeholder={t('contact.placeholder_category')}
                      errorMessage={categoryMissing && t('missing_field')}
                    />
                  )}
                <TextArea
                  label={t('contact.message')}
                  value={message}
                  onChange={(e) => {
                    setFormSubmitError(false);
                    setMessageMissing(false);
                    setMessage(e.target.value);
                  }}
                  errorMessage={messageMissing && t('missing_field')}
                />
                {process.env.GATSBY_ENVIRONMENT === 'test' && (
                  <FriendlyCaptcha
                    handleCaptchaSolution={handleCaptchaSolution}
                    handleCaptchaError={handleCaptchaError}
                    sitekey='FCMGIJ4C05M18P71'
                  />
                )}
                <InfoBox type='disclaimer'>
                  <InfoBoxText>
                    {t('contact.privacyDisclaimer', {
                      privacyPolicy: (
                        <Link
                          href={props.pageContext.privacyPolicyPagePath}
                          targetBlank
                          underline
                        >
                          {t('contact.privacyPolicy')}
                        </Link>
                      ),
                    })}
                  </InfoBoxText>
                </InfoBox>
                {formSubmitError && (
                  <InfoBox type='error' margin='2rem 0 0'>
                    <InfoBoxText>{t('api_error')}</InfoBoxText>
                  </InfoBox>
                )}
                <Button>{t('contact.send')}</Button>
              </FormSpacing>
            </form>
          )}
        </Container>
      </BlockWrapper>
    </Page>
  );
};

export default RenderOnClientHOC(ContactFormPageTemplate);

export const pageQuery = graphql`
  query ContactFormQuery($locale: String) {
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
  }
`;
